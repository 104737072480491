import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class AboutPage extends React.Component
{
    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title
        const menu = data.site.siteMetadata.menuLinks
        const tagline = data.site.siteMetadata.tagline
    
        return (
          <Layout location={this.props.location} title={siteTitle} menuLinks={menu} tagline={tagline}>
            <SEO title="Contact Derek" />
            <h1>Get in touch...</h1>
            <p>I had these grandiose ideas to create a form here that would let you send me email that I would then respond to quickly and swiftly.</p>

            <p>Inviting more emails didn’t seem like a great idea though, and I suspect I wouldn’t respond so quickly, so let’s leave it at this:</p>

            <p>
              <a href="http://twitter.com/feather">Connect with me on Twitter</a>. My DMs are open.
            </p>

            <p>
              <a href="http://linkedin.com/in/derekfeatherstone">Connect with me on LinkedIn</a>. You can send me messages there too.
            </p>

            <p>
              <a href="http://instagram.com/derek.featherstone">Connect with me on Instagram</a>. Because photos.
            </p>


          </Layout>
        )
      }

}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        tagline
        menuLinks {
          name
          link
        }
      }
    }
  }
`